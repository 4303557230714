.cookie-policy-content{
    h4{
        font-weight: 500;
        line-height: 1.2;
        margin-bottom: 0.5rem;
        font-size: 1rem;
        margin-top: 0 !important;
    }
    
    h1{
        margin-bottom: 30px !important;
    }
    
    h2{
        font-size: 1.75rem;
    }
    
    table{
        margin-top: 30px;   
        
        td{
            padding: 2px 0px;
        }
    }
    
}
