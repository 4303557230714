.successfull-content{
    padding-top: 84px;
    padding-bottom: 120px;
    
    .img-loaded {
        width: 588px;
    }

    .text {
        font-family: Roboto, sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 48px;
        line-height: 60px;
        text-align: center;
        color: #00D98A;
        margin-top: 64px;
    }
    .locate-status {
        text-align: center;
    }

    .description {
        margin-top: 24px;
        font-size: 18px;
    }

    @media all and (max-width: 767px) {
        
        padding-top: 60px;
        padding-bottom: 60px;
        
        .locate-status {
            padding-top: 60px !important;
            padding-bottom: 60px !important;
        }

        .locate-status img {
            max-width: 100%;
        }

        .text {
            font-size: 24px;
            line-height: 32px;
            margin-top: 24px;
        }

        .description {
            font-size: 16px;
        }
    }
}
