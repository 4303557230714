.contact-content{
    padding-top: 24px;
    padding-bottom: 64px;
    
    .img-mail, .img-phone {
        width: 20px;
    }
 
    .faq-box {
        padding: 30px 20px;
        background-color: #fff;
        box-shadow: 0 0px 90px rgba(0, 31, 114, 0.10);
        border-radius: 10px;
    }

    ul {
        list-style: none;
        margin: 0;
    }

    @media all and (max-width: 767px) {
        h3 {
          font-size: 1.3rem;
        }
    }
}
