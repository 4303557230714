.v-select {
    background: white;
}

.vs__selected-options {
    flex-wrap: nowrap;
}

.vs__selected div {
    display: contents;
}

.selectedCountry {
    display: inline-block;
    position: relative;
    color: #757678;
    padding-left: 10px;
}
.phone-code-container{
    
    .country-flag{
        width: 24px;
        margin-right: 5px;
    }
    .phone-code{
        display: inline-block;
        margin-right: 5px;
    }
}

.vs__dropdown-menu {
    width: 440px !important;
}

.vs__search{
    padding: 0 !important;
}
