form{
    error{
        color: red;
        font-size: 14px;
        font-weight: 500;
    }
}

.paymentForm {
    background: #F1F9FF;
    border-radius: 10px;
    width: 100%;
    padding: 24px;
    
    label {
        font-family: Roboto, sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        color: var(--payment-form-label-color);
        margin-bottom: 0.5rem;
    }

    .consent {
        font-family: "HelveticaNeue", sans-serif;
        font-size: 16px;
        line-height: 24px;
        color: #6A8CA4;
    }
   
    @media (max-width: 767px) {
        width: auto !important;
    }
}

.main-header-form{
    max-width: 600px;
    
    .form-control{
        border: none;
        box-shadow: none;
        height: 60px;
        padding: 10px 30px;
    }
    
    .vs--open .vs__search{
        padding-left: 5px !important;
    }
    
    .phone-locate-container .form-control{
        padding: 10px 10px;
    }
    
    @media (max-width: 767px) {
        .btn-locate{
            width: 100%;
            margin-bottom: 50px;    
        }
    }
}


.phone-locate-container{
    background: #fff;
    height: 60px;
    display: flex;    
    
    .v-select{
        .vs__actions{
            display: none;
        }
        .vs__selected img{
            width: 24px;
            margin: auto 10px auto 20px;
            position: static;
        }
        .vs__dropdown-toggle{
            height: 60px;
            width: 120px;
            border: none;
        } 
        .vs__dropdown-menu {
            width: 600px;
        }
    }
    
    .form-control{
        border: none;
        box-shadow: none;
    }
    
    
}


.map-phone-container{
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 40%;
    width: 600px;
    z-index: 100;
    display: flex;
    
    .phone-locate-container{
        box-shadow: 2px 2px 10px 0 rgb(0 0 0 / 50%);
        width: 100%;
    }
    
    .btn-locate{
        margin-top: 0;
        width: 140px;
    }
    
    @media (max-width: 767px) {
        width: 95%;
        flex-wrap: wrap;
        
        .phone-locate-container{
            margin-bottom: 20px;
        }
        
        .btn-locate{
            width: 100%;
        }
    }
}


    
