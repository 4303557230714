.subscription-list-content{
    table{
        th{
            padding: 0.75rem;
        }
        
        td{
            padding: 0.75rem;
        }
        
        tr:last-child td{
            border-bottom: none;
        }
        
    }
}
