.pricing-content{
    
    padding-top: 128px;
    padding-bottom: 128px;
    
    .pricing-block {
        display: flex;
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end;
    }

    .price-card {
        position: relative;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 400px;
        flex: 0 0 400px;
        -webkit-box-shadow: 0 10px 50px rgba(29, 29, 53, .1);
        box-shadow: 0 10px 50px rgba(29, 29, 53, .1);
        padding: 1.5rem 2rem;
        z-index: 2;
        border: 2px solid #1462a3;
        border-radius: 55px;
        line-height: normal;
        
        .btn{
            background: var(--pricing-button-color) !important;
            border-color: var(--pricing-button-color) !important;
            box-shadow: none !important;
            &:hover{
                background: var(--pricing-button-hover-color) !important;
                border-color: var(--pricing-button-hover-color) !important;
            }
        }
        
        .body {
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            text-align: center;
            
            .price.my-2 {
                font-size: 3rem;
                color: #29abe2;
                font-weight: 500;
                
                small {
                    color: #6f6c7f;
                    font-size: 1rem;
                }
            }
        }
    }
    
    ul, li {
        margin-bottom: 0;
        list-style: none;
        padding: 0;
    }

    h3 {
        font-size: 30px;
        font-family: 'Roboto', sans-serif;
        color: #36324a;
    }

      

    .card.pricing-features {
        padding: 4rem 1.5rem 4rem 2.5rem;
        position: relative;
        box-shadow: 0 3px 50px #a6abab;
        background: linear-gradient(45deg,#485380,#a1adbe);
        color: #fff;
        z-index: 1;
        margin: 6% 0 6% -.18rem;
        border-bottom-right-radius: 45px;
        border-top-right-radius: 45px;
        width: 280px;
    }

    .card {
        -moz-box-direction: normal;
        -moz-box-orient: vertical;
        background-color: #fff;
        border-radius: 0.25rem;
        display: flex;
        flex-direction: column;
        position: relative;
        margin-bottom: 1px;
        border: none;
    }

    .card.pricing-features ul.list.icons li.text-white {
        position: relative;
    }

    .card.pricing-features ul.list.icons li.text-white:before {
        content: '\f00c';
        font-family: FontAwesome;
        position: relative;
        margin-left: -10px;
        padding-right: 10px;
        font-size: 18px;
        color: #fff;
    }


    @media (max-width: 767px) {
        .pricing-block {
            display: block;
        }
        .card.pricing-features {
            margin: 64px auto 0 auto;
            border-radius: 45px;
        }
    }
}
