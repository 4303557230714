.error-content{
    
    .error-page {
        margin: 100px 0 100px 25%;
    }

    .error-page-right > img{
        width:100%
    }

    @media (max-width: 991px) {
        .error-page {
            margin: 0;
        }
    }
}
