.proccesing-content{
    padding-top: 84px;
    padding-bottom: 120px;
    
    .img-loaded {
        width: 588px;
    }

    .text {
        font-family: Roboto, sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 48px;
        line-height: 60px;
        text-align: center;
        color: #00D98A;
        margin-top: 64px;
    }


      .loading-bar {
        position: relative;
        width: 802px;
        margin: 0 auto;
        border-radius: 30px;
        overflow: hidden;
      }

      .loading-bar-background {
        max-width: 100%;
        width: 802px;
        height: 42px;
        background: #EDF8FC;
        border: 3px solid #4EBAE8;
        box-sizing: border-box;
        box-shadow: inset 0 8px 10px rgba(0, 0, 0, 0.1);
        border-radius: 30px;
      }

      @keyframes loading {
        0% {
          width: 1px;
        }
        5% {
          width: 1%;
        }
        10% {
          width: 2%;
        }
        20% {
          width: 5%;
        }
        25% {
          width: 10%;
        }
        40% {
          width: 20%;
        }
        70% {
          width: 30%;
        }
        75% {
          width: 70%;
        }
        80% {
          width: 72%;
        }
        90% {
          width: 95%;
        }
        100% {
          width: 100%;
        }
      }

      .loading-bar-foreground {
        border-radius: 30px;
        box-sizing: border-box;
        border: 3px solid #4EBAE8;
        background: #4EBAE8;
        position: absolute;
        top: 0;
        width: 0;
        animation-name: loading;
        animation-duration: 5s;
        animation-fill-mode: forwards;
        height: 42px;
      }

      .loading-bar-fill {
        border-radius: 30px;
        box-sizing: border-box;
        border: 3px solid #4EBAE8;
        position: absolute;
        top: 0;
        width: 0;
        animation-name: loading;
        animation-duration: 5s;
        animation-fill-mode: forwards;
        height: 42px;
        background: url("/img/front/progress-bar-item.png");
      }

      .locate-status {
        text-align: center;
      }

      .locate-status img {
        max-width: 100%;
      }

      h1 {
        font-family: Roboto, sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 48px;
        line-height: 56px;
        text-align: center;

        color: #001F72;
      }

      .loading-bar {
        max-width: 100%;
      }

      @media all and (max-width: 767px) {
        .container {
          padding-top: 60px!important;
          padding-bottom: 60px!important;
        }

        h1 {
          font-size: 32px;
          line-height: 40px;
        }

        .text {
          font-size: 24px;
          line-height: 32px;
          margin-top: 24px;
        }
      }

    
}
