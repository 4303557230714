ul{
    list-style: nonw;
    padding: 0;
}

a{
    text-decoration: none;
}

.page-content{
    padding-top: 24px;
    padding-bottom: 64px;

    h1{
        font-weight: 500;
        line-height: 1.2;
        margin-bottom: 0.5rem;
        font-size: 2rem;
    }
    
    
    h4 { 
        margin-top: 32px;  
    }
    
    h3{
        color: var(--main-subtitle-font-color);;
        font-size: 21px;
        padding-left: 15px;
        position: relative;
        margin-top: 32px;
        
        &:before {
            content: "\f105";
            font-family: FontAwesome;
            font-style: normal;
            font-weight: 400;
            left: 0;
            position: absolute;
        }
    }  
}

.box-content{
    padding: 30px 20px;
    background-color: #fff;
    box-shadow: 0 0px 90px rgba(0, 31, 114, 0.10);
    border-radius: 10px;
}

:focus-visible{
    outline: none;
}
