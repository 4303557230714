.btn-loading {
  position: relative;
  pointer-events: none;
  color: transparent !important;

  &:after {
    animation: spinAround 500ms infinite linear;
    border: 2px solid #dbdbdb;
    border-radius: 50%;
    border-right-color: transparent;
    border-top-color: transparent;
    content: "";
    display: block;
    height: 1em;
    width: 1em;
    position: absolute;
    left: calc(50% - (1em / 2));
    top: calc(50% - (1em / 2));
  }
}



@keyframes spinAround {
  from { transform: rotate(0deg); }
  to { transform: rotate(359deg); }
}

.btn-outline-primary {
    color: var(--btn-outline-primary-color);
    padding: 13px 57px;
    border: 2px solid var(--btn-outline-primary-color);;
    box-sizing: border-box;
    border-radius: 5px;
    font-family: Roboto,sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
}

.btn-outline-primary:not(:disabled):not(.disabled):focus {
    outline: none;
    box-shadow: none;
}

.btn-outline-primary:not(:disabled):not(.disabled).active, 
.btn-outline-primary:not(:disabled):not(.disabled):active, 
.show>.btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: var(--btn-outline-primary-color);
    border-color: var(--btn-outline-primary-color);
}

.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled):hover {
    color: #fff;
    background-color: var(--btn-outline-primary-color);
    border-color: var(--btn-outline-primary-color);
    outline: none;
    box-shadow: none;
}


.btn-outline-secondary {
    color: #97b0b0;
    padding: 13px 57px;
    border: 2px solid #97b0b0;
    box-sizing: border-box;
    border-radius: 5px;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
}

.btn-outline-secondary:not(:disabled):not(.disabled):focus {
    outline: none;
    box-shadow: none;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btn-outline-secondary:not(:disabled):not(.disabled):hover {
    color: #fff;
    background-color: #97b0b0;
    border-color: #97b0b0;
    outline: none;
    box-shadow: none;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active {
    background-color: #97b0b0;
}

.btn-outline-danger {
    padding: 13px 57px;
    box-sizing: border-box;
    border-width: 2px;
    border-radius: 5px;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    color: #dc3545;
}

.btn-outline-danger:not(:disabled):not(.disabled):focus {
    outline: none;
    box-shadow: none;
}

.btn-outline-danger:not(:disabled):not(.disabled):active,
.btn-outline-danger:not(:disabled):not(.disabled):hover {
    color: #fff;
    outline: none;
    box-shadow: none;
}

.btn-outline-register {
    padding: 13px 57px;
    box-sizing: border-box;
    border-radius: 5px;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    color: #00c698;
    border: 2px solid #00c698;
}

.btn-outline-register:not(:disabled):not(.disabled):focus {
    outline: none;
    box-shadow: none;
}

.btn-outline-register:not(:disabled):not(.disabled):active,
.btn-outline-register:not(:disabled):not(.disabled):hover {
    color: #fff;
    outline: none;
    box-shadow: none;
    background-color: #00c698;
    border-color: #00c698;
}

.btn-success,
.btn-success:active,
.btn-success:focus,
.btn-success:hover{
    background: #00d98b;
    border-color: #00d98b;
}

.btn-primary,
.btn-primary:active,
.btn-primary:focus,
.btn-primary:hover{
    background: #001b8a;
    border-color: #001b8a;
}

.btn.modal-submit{
    border-radius: 50px;
    color: #fff;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    overflow: hidden;
    padding: 12px 36px;
    position: relative;
    text-transform: capitalize;
    transition: all .3s linear;
    z-index: 1;
    display: block;
    width: 100%;
}

.btn-pay {
    background: #00D98B;
    border-radius: 5px;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 22px;
    line-height: 26px;
    /* identical to box height */
    text-align: center;
    color: #FFFFFF;
    /*margin-top: 28px;*/
    width: 300px;
    min-height: 48px;
    outline: none;
    border: none transparent;
}

.btn-pay:hover {
    background: #00cc81;
    color: #FFFFFF; 
}

.btn-pay:active {
    background: #00b371;
}

.btn-locate{
    border-radius: 5px;
    font-family: Roboto,sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    height: 60px;
    line-height: 26px;
    margin-top: 28px;
    outline: none;
    text-align: center;
    width: 300px;
}

.btn-locate:hover {
    background: #00cc81;
} 