@import url('https://fonts.googleapis.com/css?family=Raleway:300,400,600');
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,600');

@import 'variables';
@import 'bootstrap';
@import 'vue-select/dist/vue-select.css';

@import 'elements/card';
@import 'elements/navbar';
@import 'elements/buttons';
@import 'elements/transitions';
@import 'elements/footer';
@import 'elements/modals';
@import 'elements/forms';
@import 'elements/map';
@import 'elements/v-select';

@import 'pages/landing';
@import 'pages/contact_us';
@import 'pages/cookie_policy';
@import 'pages/terms_and_conditions';
@import 'pages/privacy_policy';
@import 'pages/faq';
@import 'pages/pricing';
@import 'pages/payment';
@import 'pages/error';
@import 'pages/successfull_payment';
@import 'pages/processing';
@import 'pages/subscription_list';

@import 'components/server-error';

@import './main';
@import './dashboard';
@import 'style.css';

