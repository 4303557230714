.container{
    padding: 0 30px;
}

.jumboheader {
    overflow: hidden;
    background: var(--landing-header-background);

    .jumbocontent {
        padding-top: 24px;
        position: relative;
    }

    
    .locate{  
        margin-top: 115px;
        margin-bottom: 50px; 
        
        
        img {
            width: 300px;
            height: auto;
            position: relative;
            left: 20px; 
        }
    }

    .locateContainer {
        position: relative;
    }

    h1 {
        font-family: Roboto,sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 48px;
        color: var(--main-title-font-color);
        margin-bottom: 28px;
        
    }

    .text {
        font-family: "HelveticaNeue",sans-serif;
        font-size: 18px;
        line-height: 22px;
        color: var(--main-subtitle-font-color);
        max-width: 680px;
        margin-bottom: 50px;
        font-weight: 700;
    }

    .text > b {
        font-style: normal;
        font-weight: bold;
        font-family: "Helvetica Neue Medium",sans-serif;
    }

    @media all and (max-width: 768px) {
        .locate {
            margin-top: 24px;
        }
        
    }

    @media all and (max-width: 400px) {
        .locate h1 {
            font-size: 39px;
        }
    }
}

.services-content{
    
    background: #fff;
    .icon-services-website {
        width: 58px;
    }

    .icon-services-networks {
        width: 54px;
    }

    .icon-services-countries {
        width: 54px;
        height: 54px;
    }

    .icon {
        display: inline-block;
        width: 54px;
        height: 54px;
    }

    .service-item {
        text-align: center;
        min-height: 280px;
        
        h3 {
            margin-top: 32px;
            margin-bottom: 16px;
            color: var(--main-subtitle-font-color);
        }

        p {
            display: inline-block;
            color: var(--main-text-font-secondary-color);
            max-width: 240px;
        }
    }

    @media (max-width: 767px) {
        .service-item:first-child {
            margin-top: 32px;
            padding-top: 30px;
        }
    }

    @media (min-width: 768px) {
        .service-item:not(:last-child) {
            border-right: 1px solid #D8E8F9;
        }

        .service-item {
            margin-top: 82px;
            margin-bottom: 94px;
            padding-top: 30px;
            min-height: 280px;
        }
    }

}

.feature-content{
    
    padding-top: 128px;
    padding-bottom: 156px;
    background: var(--main-finding-background);
    
    .icon-feature {
        width: 78px;
    }

    .icon-howitworks {
        width: 24px;
    }

    .icon-howitworks-1 {
        width: 38px;
    }

    .icon-howitworks-2 {
        width: 22px;
    }

    .icon-check {
        width: 16px;
        height: 12px;
    }

    h2 {
        font-family: Roboto, sans-serif;
        font-style: normal;
        font-weight: bold;
        display: inline-block;
        margin-bottom: 16px;
        font-size: 32px;
        line-height: 37px;
        color: var(--main-subtitle-font-color);
        height: 80px;
    }

    h2 img {
        float: left;
        margin-right: 24px;
    }

    .list-items {
        margin-left: 90px;
        padding-top: 20px;
    }

    .list-items .list-item img {
        margin: 12px;
    }

    .list-items .list-item {
        font-size: 16px;
        line-height: 40px;
        color: var(--main-text-font-secondary-color);
    }

    .how-it-works .item {
        display: table;
        margin-bottom: 20px;
    }

    .how-it-works .item .img {
        display: table-cell;
        vertical-align: top;
        text-align: center;
        padding-right: 24px;
        width: 60px;
    }

    .how-it-works .item .description {
        display: table-cell;
        vertical-align: top;
        font-family: "HelveticaNeue", sans-serif;
        font-size: 14px;
        line-height: 16px;
        color: var(--main-text-font-secondary-color);
    }

    .how-it-works .item .description h4 {
        font-family: Roboto, sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 21px;
        color: var(--main-subtitle-font-color);
    }

    @media (max-width: 767px) {
        .feature-content {
            padding-bottom: 64px;
        }
        .h2-last {
            margin-top: 64px;
        }
    }

    @media (max-width: 576px) {
        .list-items {
            margin-left: 0;
        }
        .list-items:first-of-type {
            padding-top: 20px;
        }
    }
}

.testimonials-content{
   
    background: #fff;
    
    .testimonial-item {
        margin-top: 82px;
        margin-bottom: 94px;
        min-height: 280px;
        padding: 48px 54px 20px;
        position: relative;
    }

    .testimonial-item:not(:last-child) {
        border-right: 1px solid #D8E8F9;
    }

    .testimonial-item p {
        display: inline-block;
        font-size: 20px;
        line-height: 43px;
        color: var(--main-text-font-secondary-color);
        mix-blend-mode: normal;
        opacity: 0.9;
        font-style: italic;
        font-variant: normal;
    }

    .author {
        text-align: center;
        margin-top: 16px;
        margin-bottom: 24px;
        font-family: Roboto, sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 21px;
        color: #114D5F;
    }

    .rating {
        text-align: center;
        white-space: nowrap;
    }

    .quote {
        position: absolute;
        top: 32px;
        left: 32px;
    }

    @media (max-width: 767px) {
        .testimonial-item:not(:last-child) {
          margin-bottom: 0;
          border-right: none;
        }
    }
}

.benefit-content{

    background: #fff;

    p{
        margin-bottom: 48px;
        text-align: justify;
        font-family: "HelveticaNeue", sans-serif;
        display: inline-block;
        mix-blend-mode: normal;
        opacity: 0.9;
        font-variant: normal;
        font-size: 14px;
        line-height: 1.5;
    }

    h2 {
        font-family: Roboto, sans-serif;
        font-style: normal;
        font-weight: bold;
        display: inline-block;
        margin-bottom: 16px;
        font-size: 18px;
        line-height: 21px;
    }

    p, h2 {
        display: inline-block;
        color: var(--main-text-font-color);
    }

    @media (max-width: 767px) {
        .benefit-item:not(:last-child) {
            border-right: none;
        }

        h2 {
            font-size: 19px;
        }
    }
}
