.navbar {
    font-weight: 600;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}

.fixed-header{  
    box-shadow: 0 0 14px rgb(17 69 84 / 13%);
}

.nav-item {
    .dropdown-menu {
        border: none;
        margin-top: .5rem;
        border-top: 1px solid #f2f2f2 !important;
        box-shadow: 0 4px 8px 0 rgba(0,0,0,0.12), 0 2px 4px 0 rgba(0,0,0,0.08);
    }
}

.nav-link {
    .svg-inline--fa {
        font-size: 1.4rem;
    }
}

.landing-top-navbar{
    
    .logo {
        width: 234px;
    }
  
    padding: 0.5rem 0;
    box-shadow: none;

    .navbar-toggler{
        outline:none;
    }

    .navbar-toggler:focus {
        text-decoration: none;
        outline: 0;
        box-shadow: none;
    }

    .navbar-collapse{
        flex-grow: 0;
    }

    @media all and (max-width: 767px) {
        .navbar-collapse .btn{
            margin-bottom: 8px;
            width: 100%;
        }
        .logo {
            width: 188px;
        }
    }
}

