.server-error-modal {
  height: 100%;

  .swal2-modal {
    padding: 0;
    width: calc(100% - 60px);
    height: calc(100% - 60px);
  }

  .swal2-content,
  .swal2-html-container {
    padding: 0;
    height: 100%;
  }

  iframe {
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 5px;
  }
}
