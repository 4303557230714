.modal-backdrop {
    background-color: rgba(0, 0, 0, 0.5)!important;
}

.dashboard {
    background-color: #f5f5f5;
}

.dashboard-content {
    /* Margin left has to match sidebar width */
    margin-left: 60px;
    padding-top: 40px;
    height: 100%;
}

.dashboard-content, .dashboard-content-maindiv {
    background-color: white;
}

.dashboard-content-maindiv {
    padding-bottom: 40px;
}

.sidebar i.fa {
    font-size: 16px;
}

.sidebar {
    position: fixed;
    z-index: 10;
    left: 0;
    top: 0px;
    height: 100%;
    width: 60px;
    background: #fff;
    box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.25);
    text-align: center;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}

li.icon-img {
    padding: 5px 0px;
}

li.icon-img a {
    padding: 10px 0px;
}

li.icon-img a img {
    width: 30px;
    height: 30px;
    margin: auto;
    text-align: center;
    filter: grayscale(1);
}

li.icon-img.active a img, li.icon-img:hover a img {
    filter: grayscale(0);
}

li.icon-img, li.icon-img a {
    text-align: center;
    margin: auto;
}

li.logo {
    padding: 10px 0px 15px;
}

li.logo img{
    height: 50px;
}

.map {
    /*background-image: url(../img/dashboard/map-bg.jpg);*/
    width: calc(100% - 60px);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;
    position: relative;
    border: 1px solid rgba(0,0,0,0.05);
}

/*.sidebar:hover,
.sidebar.active,
.sidebar.hovered {
  width: 250px;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  }*/
.sidebar ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.sidebar ul li {
    display: block;
}

.sidebar ul li a {
    display: block;
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    color: #444;

}

.sidebar ul li a i {
    display: inline-block;
    width: 60px;
    height: 60px;
    line-height: 60px;
    text-align: center;
    -webkit-animation-duration: .7s;
    -moz-animation-duration: .7s;
    -o-animation-duration: .7s;
    animation-duration: .7s;
    -webkit-animation-fill-mode: both;
    -moz-animation-fill-mode: both;
    -o-animation-fill-mode: both;
    animation-fill-mode: both;
}

.sidebar ul li a span {
    display: inline-block;
    height: 60px;
    line-height: 60px;
}

.sidebar ul li a:hover {
    background-color: #eee;
}

.locate-number {
    position: absolute;
    top: 40%;
    left: 0;
    right: 0;
    margin: auto;
    max-width: 480px;
    min-width: 300px;
}

.locate-number .dial-select > .toggle {
    background-color: #fff;
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
}

.locating.track-list {
    box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.28);
    transition: all 0.2s ease-in-out;
}

.locate-number .tracker-input {
    background-color: #fff;
    width: 100%;
    padding-right: 0;
}

.main {
    position: relative;
    display: block;
    /* Margin left has to match sidebar width */
    margin-left: 60px;
    top: 25px;
    left: 0;
    padding: 15px 30px;
    /*padding-left: 90px;*/
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}

.track-list {
    padding: 20px;
    background-color: #fff;
}

.track-list ul li {
    line-height: 1.4;
    border-bottom: 1px solid #d7d7d7;
    padding: 15px 10px 15px 30px;
    position: relative;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.track-list ul li:before {
    content: "";
    position: absolute;
    color: #000;
    background-color: #000;
    height: 4px;
    width: 4px;
    left: 10px;
    border-radius: 50%;
    top: 48%;
}

.track-list ul li a {
    color: #000;
}

.track-list ul li label {
    float: right;
    padding: 5px 10px;
    top: -15px;
    font-size: 12px;
    color: #fff;
    position: relative;
    border-radius: 15px;
}

.track-list ul li label.pending {
    background-color: #29abe2;
}

.track-list ul li label.located {
    background-color: #80d162;
}

.track-list ul li label.denied {
    background-color: #f05050;
}

.track-list ul li label.not_delivered {
    background-color: #4c5667;
}

.track-list ul li:hover {
    box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.28);
    transition: all 0.2s ease-in-out;
}

.track-list ul li:hover:before {
    background-color: #80d162;
    transition: all 0.2s ease-in-out;
}

.card.heading {
    background-color: #001f72;
    padding: 20px 20px;
}

.card.heading span {
    color: #fff;
    vertical-align: middle;
    line-height: 2.8;
}

td.green span {
    color: #001f72;
}

.profile-img img {
    width: 40px;
    height: 40px;
}

.image {
    background-color: #e8e8e8;
    float: left;
    margin-right: 15px;
    padding: 5px;
}

.name h4 {
    margin-bottom: 0;
    font-size: 18px;
}

.name span {
    font-size: 14px;
}

.subscriber {
    text-align: right;
    background-color: #001f72;
    padding: 15px;
    width: fit-content;
    float: right;
}

.subscriber span {
    color: #fff;
    padding-right: 10px;
    font-size: 14px;
}

.langSelectorSpan {
    display: block;
    overflow: hidden;
    padding: 10px 0px;
}

.langSelectorSpan select {
    border-radius: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
    background: white;
	width: 80%;
    text-transform: uppercase;
}


section.map {
    margin-left: 60px;
}

section.main{
    min-height: 50vh;
}

.success-payment {
    text-align: center;
}
.success-payment img.img-fluid{
    width: 78px;
}
.success-payment p{
    font-size: 24px;
    padding: 15px 0 0px;
    margin-bottom: 0;
    font-weight: bold;
    color: #1c2337;
}

.locate-logo{
    background-color: #e8eaf6;
    height: 120px;
    padding: 15px;
    border-radius: 50%;
    object-fit: contain;
    object-position: center;
}

.btn-white-sm {
    border: 1px solid #eee;
    background-color: #fff;
    padding: 5px 10px;
    font-size: 14px;
    line-height: 16px;
}

li.locating{
    list-style: none;
}

body{
    overflow-x: hidden !important;
}



/* --- */
/* BEGIN: Media queries */
/* --- */

/*@media (max-width: 1199px) {*/
@media (max-width: 1400px) {
    .track-list ul li label {
        float: right;
        padding: 3px 4px;
        top: -15px;
        font-size: 10px;
        color: #fff;
        position: relative;
    }
}

@media (max-width: 991px) {
    .table thead th {
        width: 30% !important;
    }

    .track-list ul li {
        padding: 15px 5px 15px 20px;
    }

    .track-list ul li:before {
        left: 5px;
    }

    .subscriber {
        float: none;
        margin-bottom: 20px;
        width: 100%;
    }

    .subscriber span {
        padding-right: 0;
    }

    .main {
        top: 0;
    }
}

@media (max-width: 767px) {
    .main {
        /*padding: 15px 10px;*/
        /*padding-left: 70px;*/
    }

    .locate-number {
        max-width: 400px;
        min-width: 280px;
        left: 0;
        right: 0;
    }
}

@media (max-width: 575px) {
    section.main {
        padding: 0;
    }

    section.map {
        margin-left: 0;
        padding-right: 0;
        width: 100%;
    }

    .sidebar {
        display: block;
        width: 100%;
        height: auto;
        position: relative;
    }

    .sidebar ul li {
        display: inline-block;
        padding-right: 16px;
    }

    .sidebar ul li:last-child {
        padding-right: 0;
    }

    li.logo {
        vertical-align: top;
        padding: 12px 0;
    }

    .dashboard-content, .main {
        margin-left: 0;
    }

    .locate-number {
        max-width: 280px;
        min-width: 280px;
        left: auto;
        right: 10px;
    }

    .tracker-input a.btn.bg-secondary {
        padding: 9px 15px;
        font-size: 12px;
    }

    .card.heading .col-sm-6.text-right {
        text-align: left !important;
    }

    .card.heading .col-sm-6.text-right .btn {
        padding: 8px 14px;
        margin-top: 10px;
    }

    .card.heading span {
        line-height: normal;
    }
    .footer-logo img, .logo img {
        height: 35px !important;
    }
}


@media (max-width: 374px) {
    .locate-number {
        max-width: 240px;
        min-width: 240px;
        left: auto;
        right: 10px;
    }

    .locate-number .dial-select > .toggle {
        padding-left: 10px;
        padding-right: 5px;

    }

    .locate-number .dial-select > .toggle > img {
        margin-right: .25rem;
    }

    .locate-number .tracker-input input {
        padding: 0px 8px;
    }

    a.btn {
        padding: 10px 15px;
        font-size: 12px;
    }

    .subscriber {
        padding: 5px;
    }

    .subscriber span {
        font-size: 13px;
    }
}

/* --- */
/* END: Media queries */
/* --- */
