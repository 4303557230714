.payment-content{
    .steps {
        margin-top: 48px;
        margin-bottom: 64px;
    }
    
    h3{
        color: #36324a;
    }

    .line {
        position: absolute;
        z-index: -1;
        right: 3%;
        top: 54%;
        display: inline-block;
        width: 67%;
        height: 2px;
        background-color: #DDDDDD;
    }

    .step {
        position: relative;
        font-family: "HelveticaNeue", sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        color: #757678;
    }

    .step-number {
        display: inline-block;
        width: 60px;
        height: 60px;
        border: 2px solid #DDDDDD;
        border-radius: 50%;
        box-sizing: border-box;
        text-align: center;
        line-height: 58px;

        font-family: "HelveticaNeue", sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 24px;

        color: #757678;
        padding-left: 4px;
        margin-right: 20px;
    }

    .step-number.active {
        background-color: #EEEEEE;
    }
    
    .payment-location{
        .green {
            color: #00D98B;
        }

        h1 {
            font-family: Roboto, sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            line-height: 28px;
            text-align: center;
            color:  var(--main-subtitle-font-color);
        }
    }
    
   
    sup {
        top: -0.8em;
        font-size: 50%;
    }

    .rate {
        
        margin-top: 30px;
        margin-bottom: 24px;
        padding-left: 34px;
        padding-top: 6px;
        padding-bottom: 6px;

        font-family: "HelveticaNeue", sans-serif;
        font-size: 16px;
        line-height: 19px;
        color: #4EBAE8;

        background: rgba(78, 186, 232, 0.1);
        border: 2px solid #4EBAE8;
        box-sizing: border-box;
        box-shadow: inset 4px -24px 18px rgba(17, 69, 84, 0.13);
        border-radius: 5px;
    }

    .rate-text {
        display: inline-block;
        padding-bottom: 12px;
        font-size: 18px;
    }

    .rate .amount {
        position: relative;
        left: 4px;
        bottom: -4px;
        font-family: Roboto, sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 30px;
        line-height: 35px;
        margin-bottom: -4px;

        color: var(--payment-form-rate-color);
    }

    .payment-text {
        font-family: Roboto, sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 28px;
        text-align: center;
        color: var(--main-subtitle-font-color);
    }

    .payment-text .green {
        color: #00D98B;
    }

    h1 {
        font-family: Roboto, sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 48px;
        line-height: 56px;
        color: var(--main-subtitle-font-color);
    }

    .number {
        font-family: Roboto, sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 36px;
        line-height: 42px;
        color: var(--payment-form-rate-color);
    }

    @media (max-width: 767px) {
        .rate {
          width: 100%;
        }
        .receive-of {
          display: none;
        }
    }
    
  
    @media (max-width: 767px) {
        .steps {
            display: none;
        }
    }
}

.payment-dashboard-content{
    .rate {
        width: 100%;
    }
}

.payment-description{
    .payment-about-item {
        text-align: center;
        margin-top: 82px;
    }

    .payment-about-item:last-child {
        margin-bottom: 94px;
    }

    .payment-about-item h3 {
        margin-top: 32px;
        margin-bottom: 16px;
        color: #001F72;
    }

    .payment-about-item p {
        display: inline-block;
        color: #3a3f57;
        max-width: 240px;
    }
    
    .payNote {
        padding-bottom: 46px;
        color: #3a3f57;
        font-size: 14px;
    }

    .icon-precise-location {
        width: 58px;
    }

    .icon-anonymous {
        width: 64px;
    }

    .icon-secure-payment {
        width: 42px;
    }

    .icon {
        display: inline-block;
        width: 54px;
        height: 54px;
    }
    
    .payment-about-item:not(:last-child) {
        border-right: 1px solid #D8E8F9;
    }

    .payment-about-item {
        text-align: center;
        margin-top: 82px;
        margin-bottom: 94px;
        padding-top: 30px;
        min-height: 280px;
    }
}