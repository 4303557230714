.footer {
    padding-top: 100px;
    padding-bottom: 100px;
    background: var(--footer-background);
    
    .phoneNumbers {
        display: inline-block;
    }

    .img-logo-invert {
        height: auto;
    }

    .img-phone {
        vertical-align: top;
        width: 20px;
        padding-top: 10px;
    }

    .img-mail {
        width: 20px;
    }

    .contacts {
      margin-top: 40px;
    }

    .contacts a, .contacts-company_information {
        font-family: "HelveticaNeue", sans-serif;
        font-size: 18px;
        line-height: 40px;
        color: #FFFFFF;
        mix-blend-mode: normal;
        opacity: 0.5;
        letter-spacing: 1px;
    }

    .contacts-company_information {
        line-height: 1.5;
        width: 300px;
    }

    .contacts img {
        display: inline-block;
        vertical-align: center;
        width: 20px;
        margin-right: 24px;
    }

    .footer-links h3 {
        color: #fff;
        font-family: Roboto, sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 28px;
    }

    .footer-links a {
        font-family: "HelveticaNeue", sans-serif;
        font-size: 18px;
        line-height: 24px;
        color: #FFFFFF;
        mix-blend-mode: normal;
        opacity: 0.5;
        letter-spacing: 1px;
        text-decoration: none;
    }
    
    .footer-links a.active {
        opacity: 1;        
    }

    .link-register a {
        color: #00d98b;
    }

    .footer-links .footer-link {
        margin-top: 16px;
    }

   

    .img-logo-invert {
        width: 234px;
    }

    @media all and (max-width: 767px) {
        padding-top: 70px;
        padding-bottom: 70px;
        
        .logo{
            width: 100%;
        }
        .img-logo-invert {
            width: 188px;
        }
        .footer-links {
            padding-top: 50px;
        }
        .footer-links, .footer-logo, .footer2, .payment-icons {
            text-align: center;
        }
        .contacts-company_information {
            width: 100%;
        }
    }
}

.footer2 {
    padding-top: 20px;
    padding-bottom: 20px;
    background: #fff;
    
    .copyright {
        margin-top: 24px;
    }

    .copyright a {
        color: #36BAE1;
    }
    
    .payment-icons {
        text-align: right;
    }

    .payment-icons img {
        margin-top: 20px;
        margin-bottom: 20px;
        margin-left: 12px;
    }
    
    @media all and (max-width: 767px) {
        text-align: center;
        .payment-icons {
            text-align: center;
        }
    }
}
