.modal{
    
    .modal-content{
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        border: none;
    }
    
    .modal-header {
        background: var(--modal-header-background);
        color: #fff;
        border-bottom: 1px solid #e9ecef;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        box-shadow: 0 0 10px -2px rgb(0 0 0 / 10%);
    }
    
    button.btn.btn-primary {
        background-color: var(--modal-submit-button-color);
        border-color: var(--modal-submit-button-color);
    }

    .modal-header .modal-title {
        color: #fff;
    }

    .btn-close {
        opacity: 1;
        color: #fff;
    }
    
    .btn-close svg{
        fill: #fff !important;
    }

    .modal-body {
        padding: 30px 16px 16px;
        width: 100% !important;
        background-color: #F7F7F7;
        margin: 0 auto;
        border-radius: 2px;
        overflow: hidden;
    }
    
    .notification-modal{
        .modal-body {
            background-color: #fff;
            padding-top: 16px
        }
        
        .modal-footer {
            background-color: #fff;
            padding: 5px
        }
    }
}

.modal#logout-modal{
    .modal-body{
        background: #fff;
    }
}

