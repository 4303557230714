.faq-content{
    .accordion {
        margin-top: 24px;
    }

    .accordion-header:first-child {
        border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
    }

   
    .accordion-header {
        background-color: #f7f7f9;
        margin-bottom: 0;
        padding: 20px 1.25rem;
        border-bottom: 1px solid #e8e8e8;
        padding: 0;
        i {
            float: right;
            width: 8px;
            margin: 0 25px 0 0;
            font-size: 25px;
            padding: 0;
            color: #195C9D;
        }
    }
    
    .accordion-button{
       
        padding: 20px 1.25rem;
        background: none;
        box-shadow: none;
        color: var(--main-subtitle-font-color);
        font-size: 20px;
        font-weight: 700;
        &:after{
            display: inline-block;
            font-family:  FontAwesome;
            content: "\f105";
            background: none !important;
            font-size: 30px;
            margin-right: 0;
            margin-top: 0;
            width: 8px;
            transform: none !important;
            height: 30px;
            color: #212529;
            font-weight: 300;
        }
    }
}