// // Body
//$body-bg: #f7f9fb;

// Cards
$card-spacer-x: 0.9375rem;
$card-spacer-y: 0.625rem;
$card-cap-bg: #fbfbfb;
$card-border-color: #e8eced;

// Borders
$border-radius: .125rem;
$border-radius-lg: .2rem;
$border-radius-sm: .15rem;

// Nav Pills
$nav-pills-border-radius: 0;

$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1140px,
    xxl: 1140px
) !default;

$btn-close-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#fff'><path d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/></svg>") !default;


:root{
    --landing-header-background: linear-gradient(197.58deg ,#dfe2e6 20.29%,#d5ddff 76.53%);
    --footer-background: #2a3253;    
    --primary-font-color: #212529;
    --secondary-font-color: #6A8CA4;
    --link-color: #007bff;
}
